import React from "react";
import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";
import useGetId from "../../../../hooks/useGetId.tsx";
import TableInassistence from "./TableInassistence.tsx";
import { notification } from "../../../../utils/Notifications.tsx";
import moment from "moment";
import { findWihoutAssistance } from "../../../../services/assistanceCheck.service.ts";
import { PartnerWithoutAssistance } from "../../../../models/PartnerWithoutAssistance.ts";
import ModalContainer from "../../../ModalContainer.tsx";
import ModalInassistance from "./ModalInassistance.tsx";

const Inassistence = () => {
  useAppSettings();
  const id = useGetId();
  const [loading, setLoading] = React.useState(true);
  const [records, setRecords] = React.useState<PartnerWithoutAssistance[]>([]);
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState<PartnerWithoutAssistance | null>();

  const fetchWithoutAssistance = async () => {
    try {
      const response = await findWihoutAssistance(
        moment().format("YYYY-MM-DD"),
        id
      );
      setRecords(response);
    } catch (error) {
      notification("error", error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchWithoutAssistance();
  }, []);

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Sucursales"
          basePath="/sucursales"
          activeTitle="Cubrir Puestos Urgentes"
          title="Cubrir Puestos Urgentes"
        />

        <div className="card border-0 m-4 rounded">
          <div className="tab-content p-3">
            <TableInassistence
              data={records}
              refetch={async () => await fetchWithoutAssistance()}
              openModal={(record) => {
                setRecord(record);
                setOpen(true);
              }}
            />
          </div>
        </div>
      </Layout>
      <ModalContainer
        open={open}
        title="Cubrir puesto"
        closeFn={() => setOpen(false)}
        children={
          <ModalInassistance
            close={() => setOpen(false)}
            record={record ?? ({} as any)}
            refetch={async () => await fetchWithoutAssistance()}
          />
        }
      />
    </React.Fragment>
  );
};

export default Inassistence;
