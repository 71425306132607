export interface IGeneralAttendanceReport {
  start: string;
  end: string;
}

export class GeneralAttendanceReport {
  start: string;
  end: string;

  constructor(start: string, end: string) {
    this.start = start;
    this.end = end;
  }
}

export const GeneralAttendanceReportInitial = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();

  let start, end, quincena;

  if (day <= 15) {
    start = new Date(year, month, 1);
    end = new Date(year, month, 15);
    quincena = `Primera quincena de ${today.toLocaleString('es-ES', { month: 'long' })}`;
  } else {
    start = new Date(year, month, 16);
    end = new Date(year, month + 1, 0); // El día 0 del mes siguiente es el último día del mes actual
    quincena = `Segunda quincena de ${today.toLocaleString('es-ES', { month: 'long' })}`;
  }
  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
    name: quincena,
  };
};


// Response

export interface GeneralSchedule {
  partner: Partner;
  checks:  GeneralScheduleCheck[];
}

export interface GeneralScheduleCheck {
  date:   Date;
  checks: ClockCheck[];
}

export interface ClockCheck {
  id:          number;
  checkDate:   Date;
  checkTime:   string;
  deviceId:    string;
  deviceModel: string;
  deviceName:  string;
  ip:          string;
  coords:      string;
  location:    string;
  status:      string;
  manual:      boolean;
  code:        null;
  createdAt:   Date;
  updateAt:    Date;
  branch:      CheckBranch;
}

export interface CheckBranch {
  id:   number;
  name: string;
}

export interface Partner {
  id:                 number;
  clockNumber:        number;
  message:            null;
  banDate:            Date;
  reasonUnsubscribe:  string;
  isCompleted:        boolean;
  temporarilySuspend: boolean;
  createdAt:          Date;
  updateAt:           Date;
  person:             Person;
  candidate:          Candidate;
}

export interface Candidate {
  id:                number;
  recruitmentDate:   Date;
  monthlySalary:     string;
  monthlySalaryIMSS: string;
  shiftManager:      boolean;
  comments:          string;
  createdAt:         Date;
  updateAt:          Date;
  shift:             Shift;
  branch:            CandidateBranch;
  vacancy:           Vacancy;
}

export interface CandidateBranch {
  id:         number;
  name:       string;
  message:    string;
  typeBanner: string;
  createdAt:  Date;
  updateAt:   Date;
  isActive:   boolean;
}

export interface Shift {
  id:        number;
  name:      string;
  isActive:  boolean;
  createdAt: Date;
  updateAt:  Date;
}

export interface Vacancy {
  id:          number;
  isAvailable: boolean;
  isPriority:  boolean;
  isDeleted:   boolean;
  createdAt:   Date;
  updateAt:    Date;
  position:    Position;
}

export interface Position {
  id:        number;
  name:      string;
  highRisk:  boolean;
  funtions:  string[];
  status:    boolean;
  isDeleted: boolean;
  createdAt: Date;
  updateAt:  Date;
  minSalary: string;
  maxSalary: string;
  order:     number;
}

export interface Person {
  id:                        number;
  curp:                      string;
  firstName:                 string;
  lastName:                  string;
  secondLastName:            string;
  birthdate:                 Date;
  phone:                     string;
  email:                     string;
  address:                   string;
  genere:                    string;
  maritalStatus:             string;
  rfc:                       string;
  nss:                       string;
  idMex:                     string;
  ocr:                       string;
  firstNameBeneficiary:      string;
  lastNameBeneficiary:       string;
  secondLastNameBeneficiary: string;
  relationshipBeneficiary:   string;
  phoneBeneficiary:          string;
  addressBeneficiary:        string;
  isBanned:                  boolean;
  alergias:                  string;
  tipoSangre:                string;
  enfermedad:                string;
  tratamiento:               string;
  createdAt:                 Date;
  updateAt:                  Date;
}
