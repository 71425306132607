import React from "react";
import moment from "moment";

const ModalCheckDetails = ({
  partnerId,
  setShowModal,
  checkDetails,
}: {
  partnerId: number;
  setShowModal: (_: boolean) => void;
  checkDetails: any[];
}) => {
  return (
    <div>
      {checkDetails?.length === 0 ? (
        <div className="py-4 text-center">
          <h5 className="text-secondary">
            No se encontraron detalles de chequeo.
          </h5>
        </div>
      ) : (
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          {checkDetails.map((check, index) => (
            <div
              key={index}
              className="mb-4 card p-3 bg-light d-flex flex-column flex-md-row align-items-center"
              style={{ borderRadius: "8px" }}
            >
              {/* Imagen */}
              <div className="w-80 w-md-3 mb-3 mb-md-0 me-md-4">
                {check.photo && (
                  <div className="mt-3">
                    <img
                      src={check.photoUrl}
                      alt="Foto del check"
                      className="img-fluid w-100"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="w-100 w-md-7">
                <h5 className="fw-bold">
                  Fecha: {moment(check.checkDate).format("DD/MM/YYYY")}
                </h5>
                <p>
                  <strong>Hora:</strong> {check.checkTime}
                </p>
                <p>
                  <strong>Estado:</strong> {check.status}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModalCheckDetails;
