import React, { useEffect } from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { readPositions } from "../../../services/position.service.ts";
import { useDispatch } from "react-redux";
import {
  setPositions,
  setPositionsOriginal,
} from "../../../store/slices/sucursalesSlice.ts";
import {
  readPositionsByBranch,
  readTemplates,
  updatePositions,
} from "../../../services/branchSchedule.service.ts";
import {
  readBranch,
  saveBranch,
  updateBranch,
} from "../../../services/branch.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import Layout from "../../Layout.tsx";
import Title from "../../Title.tsx";
import { Panel, PanelHeader } from "../../panel/panel.jsx";
import SucursalFormGeneral from "./SucursalFormGeneral.tsx";
import { useFormik } from "formik";
import SucursalesFormPuestos from "./SucursalesFormPuestos.tsx";
import Button from "../../Button.tsx";
import { BranchTemplateSchema } from "../../../validation/schemas/BranchTemplateSchema.ts";
import { readBusinessNames } from "../../../services/businessName.service.ts";

const SucursalesForm = () => {
  useAppSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState<any>(null);
  const [templates, setTemplates] = React.useState<any>(null);
  const [businessNames, setBusinessNames] = React.useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    const loadDependencies = async () => {
      setLoading(true);
      try {
        const positions = await readPositions();
        dispatch(setPositions(positions));
        dispatch(setPositionsOriginal(positions));

        const templatesData = await readTemplates();
        setTemplates(templatesData);

        const bussinessNames = await readBusinessNames();
        setBusinessNames(bussinessNames);

        if (id) {
          const branch = await readBranch(Number(id));
          setEditing(branch);

          const positionsA = await readPositionsByBranch(Number(id));
          const groupedPositions = positionsA.reduce((acc, current) => {
            const { position, shift, numberOfWorkers, showSchedule, isHire } =
              current;
            const positionId = position.id;

            const existing = acc.find((item) => item.puesto === positionId);

            if (existing) {
              if (shift.includes("Matutino"))
                existing.matutino += Number(numberOfWorkers);
              if (shift.includes("Intermedio"))
                existing.intermedio += Number(numberOfWorkers);
              if (shift.includes("Vespertino"))
                existing.vespertino += Number(numberOfWorkers);
            } else {
              acc.push({
                puesto: positionId,
                matutino: shift.includes("Matutino")
                  ? Number(numberOfWorkers)
                  : 0,
                intermedio: shift.includes("Intermedio")
                  ? Number(numberOfWorkers)
                  : 0,
                vespertino: shift.includes("Vespertino")
                  ? Number(numberOfWorkers)
                  : 0,
                showSchedule: showSchedule,
                isHire: isHire,
              });
            }
            return acc;
          }, []);

          await new Promise((resolve) => setTimeout(resolve, 100));

          formik.setFieldValue("puestos", groupedPositions);
        }
      } catch (error) {
        notification(
          "Error al cargar los puestos",
          "Sucedió algo al intentar cargar los puestos",
          "danger"
        );
      } finally {
        setLoading(false);
      }
    };

    loadDependencies();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: editing?.name ?? "",
      branchTemplate: editing?.templateBranch ?? -99,
      branch: editing?.branch ?? "",
      templates: templates ?? [],
      puestos: editing?.puestos ?? [],
      idBusinessName: -99,
    },
    validationSchema: BranchTemplateSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { name, idBusinessName, branchTemplate } = values;

        let branch;

        if (editing) {
          branch = await updateBranch(Number(id), {
            name,
            templateBranch: branchTemplate,
          });
        } else {
          branch = await saveBranch({
            name,
            idBusinessName,
            templateBranch: branchTemplate,
          });
        }

        await updatePositions(branch.id, { puestos: values.puestos });
        notification(
          "Sucursal guardada",
          "La sucursal se guardó correctamente",
          "success"
        );
      } catch (error) {
        console.error(error);
        notification(
          "Error al guardar la sucursal",
          "Sucedió algo al intentar guardar la sucursal",
          "danger"
        );
      } finally {
        navigate("/sucursales");
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (editing) {
      formik.setFieldValue("name", editing.name);
      formik.setFieldValue("branchTemplate", editing.templateBranch?.id);
      formik.setFieldValue("branch", editing.branch);
    }
  }, [editing]);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle={`${id ? "Editar" : "Nueva"} sucursal`}
        title={`${id ? "Editar" : "Nueva"} sucursal`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la sucursal
          </PanelHeader>
          <SucursalFormGeneral
            formik={formik}
            businessNames={businessNames}
            editing={!!editing}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Puestos de la sucursal
          </PanelHeader>
          <SucursalesFormPuestos
            error={formik.errors.puestos}
            formik={formik}
            templates={templates}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={formik.handleSubmit}
              title="Guardar"
              variant="azul"
              type="submit"
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SucursalesForm;
