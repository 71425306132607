import * as yup from "yup";

const InassistanceSchema = yup.object({
  partnerReplacement: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),

  entryTime: yup.string().required("Campo requerido"),
  observations: yup.string().required("Campo requerido"),
});

export default InassistanceSchema;
