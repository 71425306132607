import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";
import {
  setDefaultSort,
  setPage,
  setSortBy,
} from "../../../../store/slices/duplicateDevicesSlice.ts";
import { fixedHeadStyle, fixedStyle } from "../../../../utils/constanst.js";

moment.locale("es");

const TableHeader: React.FC<{
  columns: { label: string; sortKey?: string }[];
  sortBy: string;
  defaultSort: string;
  onSort: (key: string) => void;
}> = ({ columns, sortBy, defaultSort, onSort }) => (
  <thead className="sticky-thead table-light text-center">
    <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
      {columns.map(({ label, sortKey }) => (
        <th
          key={label}
          className="align-middle"
          rowSpan={2}
          style={{ cursor: sortKey ? "pointer" : "default" }}
          onClick={() => sortKey && onSort(sortKey)}
        >
          {label}
          {sortBy === sortKey && (
            <i
              className={`fa-solid fa-sort-${
                defaultSort === "asc" ? "up" : "down"
              }`}
              style={{ marginLeft: "5px" }}
            />
          )}
        </th>
      ))}
    </tr>
  </thead>
);

const TableRow: React.FC<{
  record: any;
}> = ({ record }) => {
  const renderPartnerInfo = (key: string) =>
    record.partners.map((partner: any, index: number) => (
      <div
        className="items-center justify-center"
        key={index}
        style={{ marginBottom: "10px" }}
      >
        <p>
          {key === "checkDate"
            ? format(parseISO(partner[key]), "dd 'de' MMMM yyyy", { locale: es })
            : partner[key]}
        </p>
      </div>
    ));

  return (
    <tr>
      <td className="text-center">{record.deviceId}</td>
      <td className="text-center">{record.deviceModel}</td>
      <td className="text-center">{record.deviceName}</td>
      <td>
        {record.partners.map((partner: any, index: number) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <p>
              {partner.partner.person.firstName}{" "}
              {partner.partner.person.lastName}{" "}
              {partner.partner.person.secondLastName}
            </p>
          </div>
        ))}
      </td>
      <td className="text-center">{renderPartnerInfo("checkDate")}</td>
      <td className="text-center">{renderPartnerInfo("checkTime")}</td>
      <td className="text-center">
        {record.partners.map((partner: any, index: number) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <p>{partner.partner.candidate.branch.name}</p>
          </div>
        ))}
      </td>
    </tr>
  );
};

const TableDuplicateDevice: React.FC = () => {
  const { records, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.duplicateDevice
  );

  const dispatch = useDispatch();

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const columns = [
    { label: "Device ID", sortKey: "deviceId" },
    { label: "Modelo", sortKey: "deviceModel" },
    { label: "Nombre del dispositivo", sortKey: "deviceName" },
    { label: "Colaboradores" },
    { label: "Fecha de chequeo" },
    { label: "Hora de chequeo" },
    { label: "Sucursal" },
  ];

  return (
    <div
      className="table-responsive mb-3"
      style={{ overflowY: "auto", maxHeight: "500px" }}
    >
      <table className="table table-panel text-nowrap align-middle mb-0">
        <TableHeader
          columns={columns}
          sortBy={sortBy}
          defaultSort={defaultSort}
          onSort={setSort}
        />
        <tbody>
          {records && records.length > 0 ? (
            records.map((record: any, index: number) => (
              <TableRow key={index} record={record} />
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="py-4">
                  <div className="d-flex">
                    <img
                      src={EmptyImage}
                      alt="Sin datos"
                      className="mx-auto w-250px"
                    />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no existen datos
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableDuplicateDevice;