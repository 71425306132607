import React from "react";
import { useFormik } from "formik";

import moment from "moment";
import XLSX from "xlsx-js-style";
import {
  ClockCheck,
  GeneralAttendanceReportInitial,
  GeneralSchedule,
} from "../../../models/GeneralAttendanceReport.ts";
import { generalAttendanceReportSchema } from "../../../validation/schemas/GeneralAttendanceReportSchema.ts";
import CustomInput from "../../formik/CustomInput.tsx";
import { notification } from "../../../utils/Notifications.tsx";
import Loader from "../../Loader.tsx";
import { findAssistanceByPartnerId } from "../../../services/assistanceCheck.service.ts";
import {
  calculateExtraMinutes,
  getPreviusRecords,
  getUnscheduleRecords,
  isLastRecordDate,
} from "../../../utils/index.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import { dataaaa } from "./trash.ts";
import { toCamelCase } from "../../../hooks/toCameCase.tsx";

const GeneralAttendanceReportModal = ({ closeFn }: { closeFn: () => void }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const formik = useFormik({
    initialValues: GeneralAttendanceReportInitial(),
    validationSchema: generalAttendanceReportSchema,
    onSubmit: async (values) => {
      const data = {
        start: moment(values.start).format("YYYY-MM-DD"),
        end: moment(values.end).format("YYYY-MM-DD"),
      };

      try {
        const response = await findAssistanceByPartnerId(data);

        await downloadReport(response, values.name);
      } catch (error) {
        notification("", error.message, "danger");
      } finally {
        // closeFn();
      }
    },
  });

  const downloadReport = async (data: GeneralSchedule[], name: string) => {
    // Agregar fecha de consulta, quien lo genero y rango de fechas
    // Guardar con el nombre del rango de fechas

    // STEP 1: Create a new workbook
    const wb = XLSX.utils.book_new();

    const head = [
      "Fechas consultadas:",
      `${moment(formik.values.start).format("MMMM D [del] YYYY")}`,
      `${moment(formik.values.end).format("MMMM D [del] YYYY")}`,
    ];
    XLSX.utils.sheet_add_aoa(wb, [head], { origin: "A1" });
    const userGenerated = ["Generado por:", `${user?.name} ${user?.lastName}`];
    XLSX.utils.sheet_add_aoa(wb, [userGenerated], { origin: "A2" });

    const headers = [
      "Chilito",
      "Sucursal Asignada",
      "Turno",
      "Puesto",
      "Sucursal",
      "Fecha",
      "Entrada",
      "Salida",
    ];
    XLSX.utils.sheet_add_aoa(wb, [headers], { origin: "A3" });
    let rowIndex = 5;
    const backgrounds = [
      "9690f48a",
      "90f4d98a",
      "f4ba90a3",
      "f1f490a3",
      "90a3f4a3",
      "f4c490a3",
    ];
    // STEP 2: Create data rows and styles
    let ws;
    let partnerIndex = 1;
    for (const { partner, checks } of data) {
      // const color = backgrounds[partnerIndex % 2];
      const color = backgrounds[partnerIndex % backgrounds.length]; 


      const name = toCamelCase(
        `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
      );
      const position = partner.candidate.vacancy.position.name;
      const branchName = partner.candidate.branch.name;
      const shift = partner.candidate.shift.name;

      const total = checks.reduce((acc, item) => acc + item?.checks?.length, 0);
      if (total === 0) continue;

      checks.map(({ checks, date }) => {
        const branches = [...new Set(checks.map((r) => r.branch?.id))];

        branches.map((branch) => {
          const filterChecks = checks.filter(
            (record) => record.branch.id === branch
          );

          const start = filterChecks.shift();
          const end = filterChecks.filter((r) => r.id !== start?.id).pop();

          if (!start) return;

          const branchCheck = start.branch.name;

          const row = [
            Cell(name, color),
            Cell(branchName, color),
            Cell(shift, color),
            Cell(position, color),
            Cell(branchCheck, color),
            Cell(String(date), color),
            Cell(String(start?.checkTime || ""), color),
            Cell(String(end?.checkTime || ""), color),
          ];

          ws = XLSX.utils.sheet_add_aoa(wb, [row], {
            origin: `A${rowIndex}`,
          });
          rowIndex += 1;
        });
      });

      rowIndex += 1;
      partnerIndex += 1;
    }

    ws["!cols"] = [
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
    ];

    // STEP 3: Create worksheet with rows; Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Registros");
    XLSX["!cols"] = [{ wch: 30 }];

    // STEP 4: Write Excel file to browser
    XLSX.writeFile(
      wb,
      `Reporte - ${moment(formik.values.start).format("DD-MM-YYYY")} - ${moment(
        formik.values.end
      ).format("DD-MM-YYYY")}.xlsx`
    );
  };

  return (
    <React.Fragment>
      <Loader isLoading={formik.isSubmitting} />
      <div className="row">
        <p className="fs-12px fst-italic">Indica la fecha de inicio y fin *</p>
        <CustomInput
          formik={formik}
          field="start"
          label="Inicio"
          placeholder=""
          type="date"
          sm={8}
        />
        <CustomInput
          formik={formik}
          field="end"
          label="Fin"
          placeholder=""
          type="date"
          sm={8}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          Descargar
        </button>
      </div>
    </React.Fragment>
  );
};

export default GeneralAttendanceReportModal;

const Cell = (text: string = "", color: string) => {
  return {
    v: text,
    t: "s",
    s: { fill: { fgColor: { rgb: color } } },
  };
};
