import React from "react";
import { IFormikFieldProps } from "../../interfaces/formik/IFormikField";

const CustomSwitch = ({
  sm = 12,
  smLabel = 3,
  hidden = false,
  required = true,
  readonly = false,
  unclickable = false,
  onChange = () => { },
  classInput = "",
  ...props
}: IFormikFieldProps & { classInput?: string }) => {
  const error =
    props.formik.touched[props.field] &&
    Boolean(props.formik.errors[props.field]);

  return (
    <div className={`row fs-13px col-md-12 ${classInput}`} >
      <label
        className={`form-label col-form-label col-md-${smLabel} text-end`}
        style={{ color: error ? "red" : "inherit" }}
      >
        {props.label}&nbsp;
        {required && (
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        )}
      </label>
      <div
        className={`col-md-${sm}`}
        style={unclickable ? { cursor: "not-allowed" } : {}}
      >
        <div className="form-check form-switch">
          <input
            type="checkbox"
            className={`form-check-input ${error ? "is-invalid" : ""}`}
            id={props.field}
            name={props.field}
            onChange={(event: any) => {
              onChange();
              props.formik.handleChange(event);
            }}
            checked={props.formik.values[props.field]}
            readOnly={readonly}
            disabled={props.disabled}
            style={unclickable ? { pointerEvents: 'none' } : {}}
          />
        </div>
        <div
          className="invalid-feedback"
          style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
        >
          {props.formik.touched[props.field] &&
            props.formik.errors[props.field] ? (
            <>{props.formik.errors[props.field]}</>
          ) : (
            <p> </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSwitch;