import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as momentTZ from "moment-timezone";
import "moment/locale/es";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";
import {
  setDefaultSort,
  setPage,
  setSortBy,
} from "../../../../store/slices/chequeoSlice.ts";
import { formatDate, roundToZeroDecimals } from "../../../../utils/index.ts";
import { fixedHeadStyle, fixedStyle } from "../../../../utils/constanst.js";
// import { AssistanceCheck } from "../../../../models/ScheduleRecord.ts";
import { Partner } from "../../../../models/Partner.ts";
import { AssistanceCheck } from "../../../../models/IncidentRecord.ts";
import RowUnschedule from "./check/RowUnschedule.tsx";

moment.locale("es");

const TableChequeo = () => {
  const { records, sortBy, defaultSort, unscheduleChecks } = useSelector(
    (state: RootState) => state.chequeo
  );

  const dispatch = useDispatch();

  const calcularMinutosExtra = (
    horaSalidaHorario: string,
    horaSalidaRegistro: string
  ) => {
    if (!horaSalidaHorario || !horaSalidaRegistro) return "";
    const start = moment(horaSalidaHorario, "HH:mm");
    const end = moment(horaSalidaRegistro, "HH:mm:ss");
    const diff = end.diff(start, "minutes");
    return diff > 0 ? diff : 0;
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };

  const isLastRecordDate = (index: number) => {
    if (index === records.length - 1) return true;

    const record = records[index];
    const nextRecord = records[index + 1];

    const recordDate = moment(record?.exactDate).tz("America/Mexico_City");
    const nextRecordDate = moment(nextRecord?.exactDate).tz(
      "America/Mexico_City"
    );

    return !recordDate.isSame(nextRecordDate, "day");
  };

  const getUnscheduleRecords = (index: number) => {
    const totalRecords  = records.length;
    const record = records[index];
  
    if (index === 0) return [{ date: record?.exactDate, checks: unscheduleChecks }];
  
    const nextRecord = records[index + 1];
  
    const currentRecorDate = momentTZ(record?.exactDate).tz("America/Mexico_City");
    const nextDate = momentTZ(nextRecord?.exactDate).tz("America/Mexico_City").subtract(1, 'days');
  
    const render: any[] = [];
    
    for (
      let date = currentRecorDate;
      date.isSameOrBefore(nextDate);
      date.add(1, "days")
    ) {
      const checks = unscheduleChecks.filter(
        (check) => check.date === date.format("YYYY-MM-DD")
      );
  
      render.push({ checks, date: date.format("YYYY-MM-DD") });
    }

    if(totalRecords === index + 1) {
      const checks = unscheduleChecks.filter(
        (check) => check.date === record?.exactDate
      );
  
      render.push({ checks, date: record?.exactDate });
    }
  
    return render;
  };

  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
            <th onClick={() => setSort("id")}>
              Id {sortBy === "id" && getIconSort()}
            </th>
            <th onClick={() => setSort("partner")}>
              Colaborador {sortBy === "partner" && getIconSort()}
            </th>
            <th onClick={() => setSort("position")}>
              Posición {sortBy === "position" && getIconSort()}
            </th>
            <th onClick={() => setSort("shift")}>
              Turno {sortBy === "shift" && getIconSort()}
            </th>
            <th onClick={() => setSort("dayOfWeek")}>
              Día de la semana {sortBy === "dayOfWeek" && getIconSort()}
            </th>
            <th onClick={() => setSort("startTime")}>
              Hora entrada horario {sortBy === "startTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("endTime")}>
              Hora salida horario {sortBy === "endTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceCheck")}>
              Hora entrada registrada{" "}
              {sortBy === "assistanceCheck" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceCheckout")}>
              Hora salida registrada{" "}
              {sortBy === "assistanceCheckout" && getIconSort()}
            </th>
            <th onClick={() => setSort("requiredTime")}>
              Minutos requeridos {sortBy === "requiredTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("workedTime")}>
              Minutos trabajados {sortBy === "workedTime" && getIconSort()}
            </th>
            <th onClick={() => setSort("extraMinutes")}>
              Minutos extra {sortBy === "extraMinutes" && getIconSort()}
            </th>
            <th onClick={() => setSort("missingMinutes")}>
              Minutos faltantes {sortBy === "missingMinutes" && getIconSort()}
            </th>
            <th onClick={() => setSort("assistanceStatus")}>
              Estatus de asistencia{" "}
              {sortBy === "assistanceStatus" && getIconSort()}
            </th>
            <th onClick={() => setSort("isReplacement")}>
              Es reemplazo {sortBy === "isReplacement" && getIconSort()}
            </th>
            <th onClick={() => setSort("observations")}>
              Observaciones {sortBy === "observations" && getIconSort()}
            </th>
            <th onClick={() => setSort("programado")}>
              Estaba programado {sortBy === "programado" && getIconSort()}
            </th>
            <th onClick={() => setSort("checkType")}>Tipo de chequeo</th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 ? (
            records.map((item, index) => {
              const isLast = isLastRecordDate(index);
              const person = item.partner.person;
              const horaEntradaHorario = item.startTime?.split(" - ")[0];
              const horaSalidaHorario = item.endTime?.split(" - ")[1];
              const horaEntradaRegistrada = item.assistanceCheck
                ? moment(item.assistanceCheck.checkTime, "HH:mm:ss").format(
                    "HH:mm"
                  )
                : "";
              const horaSalidaRegistrada = item.assistanceCheckout
                ? moment(item.assistanceCheckout.checkTime, "HH:mm:ss").format(
                    "HH:mm"
                  )
                : "";
              const minutosExtra = calcularMinutosExtra(
                horaSalidaHorario,
                horaSalidaRegistrada
              );

              const unScheduleRecords = getUnscheduleRecords(index);

              return (
                <React.Fragment key={item.id}>
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td
                      style={{ ...fixedStyle } as React.CSSProperties}
                    >{`${person.firstName} ${person.lastName} ${person.secondLastName}`}</td>
                    <td>{item.positionName}</td>
                    <td>{item.shift}</td>
                    <td>{formatDate(item?.exactDate)}</td>
                    <td>{horaEntradaHorario || ""}</td>
                    <td>{horaSalidaHorario || ""}</td>
                    <td>{horaEntradaRegistrada || ""}</td>
                    <td>{horaSalidaRegistrada || ""}</td>
                    <td>{roundToZeroDecimals(item?.requiredTime) ?? 0}</td>
                    <td>{roundToZeroDecimals(item?.workedTime)}</td>
                    <td>{roundToZeroDecimals(item?.extraMinutes)}</td>
                    <td>
                      {roundToZeroDecimals(
                        !item?.workedTime && !item?.extraMinutes
                          ? item?.requiredTime
                          : item?.missingMinutes
                      )}
                    </td>
                    <td>{item.assistanceStatus}</td>
                    <td>{item.isReplacement ? "Sí" : "No"}</td>
                    <td>{item.observations}</td>
                    <td>{Number(item.positionY) !== -1 ? "Sí" : "No"}</td>
                    {/* <td>{item.positionY === -}</td> */}
                    <td>
                      {item.assistanceCheck?.code ? "Código" : "Dispositivo"}
                    </td>
                  </tr>
                  {isLast &&
                    unScheduleRecords &&
                    unScheduleRecords.map((record, index) => {
                      const checks = record?.checks.at(0).records
                        ?.records as AssistanceCheck[];
                      const partners = record?.checks.at(0).records
                        ?.partners as Partner[];

                      if (checks.length === 0) return <></>;
                      if (partners.length === 0) return <></>;

                      return (
                        <>
                          {partners.map((partner) => {
                            const start = checks
                              .filter((r) => r.partner.id === partner.id)
                              .shift();

                            const end = checks
                              .filter(
                                (r) =>
                                  r.partner.id === partner.id &&
                                  r.id !== start?.id
                              )
                              .pop();

                              const diferenceInMinutes = moment(end?.checkTime, "HH:mm:ss").diff(moment(start?.checkTime, "HH:mm:ss"), "minutes");

                            return (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td
                                  style={
                                    {
                                      ...fixedStyle,
                                      backgroundColor: "#ffef005c"
                                    } as React.CSSProperties
                                  }
                                >{`${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`}</td>
                                <td>
                                  {partner.candidate.vacancy.position.name}
                                </td>
                                <td>{partner.candidate.shift.name}</td>
                                <td>{formatDate(String(start?.createdAt))}</td>
                                <td></td>
                                <td></td>
                                <td>
                                  {start ? start.checkTime : "Sin información"}
                                </td>
                                <td>
                                  {end ? end.checkTime : "Sin información"}
                                </td>
                                <td></td>
                                <td>{isNaN(diferenceInMinutes) ? 0:diferenceInMinutes}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>No</td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                </React.Fragment>
              );
            })
          ) : (
            <></>
          )}
          {records.length === 0 &&
              
              unscheduleChecks.reduce((acc, item)=> acc+ item.records.records.length  , 0) > 0 && (
              <RowUnschedule unscheduleChecks={unscheduleChecks} />
            )}
          {records.length === 0 &&
              unscheduleChecks.reduce((acc, item)=> acc+ item.records.records.length  , 0) === 0 && (
              <tr>
                <td colSpan={12}>
                  <div className="py-4">
                    <div className="d-flex">
                      <img
                        src={EmptyImage}
                        alt=""
                        className="mx-auto w-250px"
                      />
                    </div>
                    <h5 className="text-secondary text-center fs-20px">
                      Aún no existen datos
                    </h5>
                  </div>
                </td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};

export default TableChequeo;
