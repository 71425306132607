import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { BranchTemplateSchema } from "../../validation/schemas/BranchTemplateSchema.ts";
import Title from "../Title.tsx";
import { Panel, PanelHeader } from "../panel/panel.jsx";
import Layout from "../Layout.tsx";
import PlantillaFormEdit from "./PlantillaFormEdit.tsx";
import PlantillaFormPuestos from "./PlantillaFormPuestos.tsx";
import { useDispatch } from "react-redux";
import { readPositions } from "../../services/position.service.ts";
import {
  setPositions,
  setPositionsOriginal,
} from "../../store/slices/branchTemplateSlice.ts";
import Button from "../Button.tsx";
import {
  readTemplate,
  savePositions,
  saveTemplates,
  updateTemplates,
} from "../../services/branchSchedule.service.ts";
import { notification } from "../../utils/Notifications.tsx";

type Props = {};

const NewPlantilla = (props: Props) => {
  useAppSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState<any>(null);

  const { id } = useParams();

  React.useEffect(() => {
    const loadDependencies = async () => {
      setLoading(true);
      try {
        const positions = await readPositions();
        const orderPostions = positions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        dispatch(setPositions(orderPostions));
        dispatch(setPositionsOriginal(orderPostions));

        if (id) {
          const template = await readTemplate(Number(id));
          setEditing(template);
        }
      } catch (error) {
        notification(
          "Error al cargar los puestos",
          "Sucedió algo al intentar cargar los puestos",
          "danger"
        );
      } finally {
        setLoading(false);
      }
    };

    loadDependencies();
  }, []);

  React.useEffect(() => {
    if (editing && editing?.id) {
      formik.setFieldValue("name", editing.name);

      const groupedPositions = editing.positions.reduce((acc, current) => {
        const { position, shifts, numberOfWorkers, showSchedule, isHire } =
          current;
        const positionId = position.id;

        const existing = acc.find((item) => item.puesto === positionId);

        if (existing) {
          if (shifts === "Matutino")
            existing.matutino += parseInt(numberOfWorkers, 10);
          if (shifts === "Intermedio")
            existing.intermedio += parseInt(numberOfWorkers, 10);
          if (shifts === "Vespertino")
            existing.vespertino += parseInt(numberOfWorkers, 10);
        } else {
          acc.push({
            puesto: positionId,
            matutino: shifts === "Matutino" ? parseInt(numberOfWorkers, 10) : 0,
            intermedio:
              shifts === "Intermedio" ? parseInt(numberOfWorkers, 10) : 0,
            vespertino:
              shifts === "Vespertino" ? parseInt(numberOfWorkers, 10) : 0,
            showSchedule: showSchedule,
            isHire: isHire,
          });
        }

        return acc;
      }, []);

      formik.setFieldValue("puestos", groupedPositions);
    }
  }, [editing]);

  const formik = useFormik({
    initialValues: {
      name: "",
      puestos: [] as any,
    },
    validationSchema: BranchTemplateSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const template = id
          ? await updateTemplates(Number(id), values.name)
          : await saveTemplates(values.name);

        const saveAllPositions = async (templateId, puestos) => {
          const allPositions = {
            templateId: templateId,
            positions: puestos.flatMap((puesto) => [
              {
                position: puesto.puesto,
                numberOfWorkers: puesto.matutino,
                shifts: "Matutino",
                isHire: puesto.isHire,
                showSchedule: puesto.showSchedule,
              },
              {
                position: puesto.puesto,
                numberOfWorkers: puesto.intermedio,
                shifts: "Intermedio",
                isHire: puesto.isHire,
                showSchedule: puesto.showSchedule,
              },
              {
                position: puesto.puesto,
                numberOfWorkers: puesto.vespertino,
                shifts: "Vespertino",
                isHire: puesto.isHire,
                showSchedule: puesto.showSchedule,
              },
            ]),
          };

          await savePositions(allPositions);
        };

        await saveAllPositions(template.id, values.puestos);

        notification(
          "Plantilla guardada",
          "La plantilla se guardó correctamente",
          "success"
        );
        formik.resetForm(); 
        navigate("/plantillas");
      } catch (error) {
        console.error(error);
        notification(
          "Error al guardar la plantilla",
          "Sucedió algo al intentar guardar la plantilla",
          "danger"
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Plantillas"
        basePath="/plantillas"
        activeTitle={`${id ? "Editar" : "Nueva"} plantilla`}
        title={`${id ? "Editar" : "Nueva"} plantilla`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la plantilla
          </PanelHeader>
          <PlantillaFormEdit formik={formik} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Colaboradores de la plantilla
          </PanelHeader>
          <PlantillaFormPuestos formik={formik} error={formik.errors.puestos} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/puestos")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewPlantilla;
