import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useFormik } from 'formik';
import { BranchTemplateModalSchema } from '../../../validation/schemas/BranchTemplateModalSchema.ts';
import { notification } from '../../../utils/Notifications.tsx';
import CustomSelectField from '../../formik/CustomSelectField.tsx';
import CustomInput from '../../formik/CustomInput.tsx';
import CustomSwitch from '../../formik/CustomSwitch.tsx';

type Props = {}

const SucursalesModalPuestos = ({ fomik, closeFn, currentRecord }) => {
  const { positions } = useSelector((state: RootState) => state.sucursales);

  const formikModal = useFormik({
    initialValues: {
      puesto: currentRecord?.puesto ?? 0,
      matutino: currentRecord?.matutino ?? 0,
      intermedio: currentRecord?.intermedio ?? 0,
      vespertino: currentRecord?.vespertino ?? 0,
      showSchedule: currentRecord?.showSchedule ?? true,
      isHire: currentRecord?.isHire ?? true,
    },
    validationSchema: BranchTemplateModalSchema,
    onSubmit: async (values) => {
      try {
        if (currentRecord) {
          const newPuestos = fomik.values.puestos.map((puesto) => {
            if (puesto.puesto === currentRecord.puesto) {
              return {
                ...puesto,
                matutino: values.matutino,
                intermedio: values.intermedio,
                vespertino: values.vespertino,
                showSchedule: values.showSchedule,
                isHire: values.isHire,
              }
            }
            return puesto;
          });
          fomik.setFieldValue('puestos', newPuestos);
          return
        } else {
          fomik.setFieldValue('puestos', [...fomik.values.puestos, values]);
        }
      } catch (error) {
        console.log('error', error);
        notification('Error', 'Sucedió un error al seleccionar el puesto', 'danger');
      } finally {
        closeFn();
      }
    }
  });
  const sortedPositions = [...positions].sort((a, b) => a.order - b.order);

  return (
    <div>
      <CustomSelectField
        formik={formikModal}
        field="puesto"
        list={!currentRecord ? sortedPositions.filter((position) => !fomik.values.puestos.some((puesto) => puesto.puesto === position.id)) : sortedPositions}
        label="Puesto"
        disabled={!!currentRecord}
      />
      <div style={{ width: '100%', paddingInline: 20 }}>
        <div style={{ width: '100%', height: '1px', background: '#E3E3E3', marginBottom: 20 }} />
      </div>
      <p className='text-center fs-5 fw-600 mb-4'>Número de colaboradores por turno</p>
      <CustomInput
        formik={formikModal}
        field="matutino"
        label="Matutino"
        placeholder="No. colaboradores matutino"
        smLabel={3}
        sm={9}
        type='number'
      />
      <CustomInput
        formik={formikModal}
        field="intermedio"
        label="Intermedio"
        placeholder="No. colaboradores intermedio"
        smLabel={3}
        sm={9}
        type='number'
      />
      <CustomInput
        formik={formikModal}
        field="vespertino"
        label="Vespertino"
        placeholder="No. colaboradores vespertino"
        smLabel={3}
        sm={9}
        type='number'
      />
       <CustomSwitch
        formik={formikModal}
        field="showSchedule"
        label="Mostrar en horario"
        smLabel={4}
        sm={8}
      />
      <CustomSwitch
        formik={formikModal}
        field="isHire"
        label="Contratable"
        smLabel={4}
        sm={8}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formikModal.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </div>
  )
}

export default SucursalesModalPuestos