import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateRemainingWildcards } from "../../../../services/assistenceCode.ts";

const ModalUpdateWildcards = ({
  partnerId,
  setShowModal,
  reloadPage,
  remainingWildcards
}: {
  partnerId: number;
  setShowModal: (_: boolean) => void;
  reloadPage: () => void;
  remainingWildcards: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      newCount: remainingWildcards || 0,
    },
    validationSchema: Yup.object({
      newCount: Yup.number()
        .required("La cantidad es obligatoria")
        .min(0, "La cantidad debe ser mayor o igual a 0"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateRemainingWildcards(partnerId, parseInt(values.newCount));
        setShowModal(false);
        reloadPage();
      } catch (error) {
        console.error("Error al actualizar la cantidad de wildcards:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="newCount" className="form-label">
            Nueva cantidad de wildcards *
          </label>
          <input
            type="number"
            id="newCount"
            className="form-control"
            value={formik.values.newCount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.newCount && formik.errors.newCount ? (
            <div className="text-danger">{formik.errors.newCount}</div>
          ) : null}
        </div>

        <div className="d-flex flex-row mt-4">
          <button
            type="button"
            className="btn btn-secondary ms-auto me-2"
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading || !formik.isValid || !formik.dirty}
          >
            {loading ? "Guardando..." : "Guardar"}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ModalUpdateWildcards;
