import React from "react";
import { findAllAssistance, findeDuplicateDeviceCheck } from "../../../../services/assistanceCheck.service.ts";
import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";
import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import {
  setLoading,
  setRecords,
  setTotal,
} from "../../../../store/slices/duplicateDevicesSlice.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import { useParams } from "react-router-dom";
import { findAllSchedulesByBranch } from "../../../../services/branchSchedule.service.ts";
import FilterDuplicateDevices from "./FilterDuplicateDevices.tsx";
import TableDuplicateDevice from "./TableDuplicateDevice.tsx";
import PaginationDuplicateDevice from "./PaginationDuplicateDevice.tsx";

const ReportDuplicateDevices = () => {
  useAppSettings();
  // const { id } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  let id;

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }
  
  const dispatch = useDispatch();
  const { page, limit, param, fetch, query, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.duplicateDevice
  );

  React.useEffect(() => {
    fetchPuestos();
  }, [page, limit, param, fetch, query, sortBy, defaultSort]);

  const fetchPuestos = async () => {
    dispatch(setLoading(true));
    try {
      if (!query.includes("dateStart")) {
        dispatch(setLoading(false));
        return;
      }
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend =
        query +
        `&sort=${sortBy}&order=${defaultSort}&limit=${limit}&offset=${offset}`;
      console.log("QUERY TO SEND ", queryToSend);
      const response = await findeDuplicateDeviceCheck(Number(id), queryToSend);
      console.log("QUERYYYY PARA DUPLICATE DEVICES ", response);
      dispatch(setRecords(response.records));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Layout>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle="ReportDuplicateDevicess"
        title="Resumen de registros"
      />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <FilterDuplicateDevices id={id} />
            <TableDuplicateDevice />
            <PaginationDuplicateDevice />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportDuplicateDevices;