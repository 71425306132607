import React from "react";
import moment from "moment";
import { Unschedule } from "../../../../../models/Unschedule";
import { fixedStyle } from "../../../../../utils/constanst";
import { formatDate } from "../../../../../utils/index.ts";

const RowUnschedule = ({
  unscheduleChecks,
}: {
  unscheduleChecks: Unschedule[];
}) => {
  return (
    <React.Fragment>
      {unscheduleChecks.map((item, index) => (
        <React.Fragment>
          {item.records.partners.map((partner, index) => {
            if(!partner?.candidate) return <></>
            const start = item.records.records
              .filter((r) => r.partner.id === partner.id)
              .shift();
            const end = item.records.records
              .filter((r) => r.partner.id === partner.id && r.id !== start?.id)
              .pop();

              const diferenceInMinutes = moment(end?.checkTime, "HH:mm:ss").diff(moment(start?.checkTime, "HH:mm:ss"), "minutes");

            return (
              <tr key={index}>
                <td>{start?.id}</td>
                <td
                  style={
                    {
                      ...fixedStyle,
                      backgroundColor: "#ffef005c",
                    } as React.CSSProperties
                  }
                >{`${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`}</td>
                <td>{partner.candidate.vacancy.position.name }</td>
                <td>{partner.candidate.shift.name}</td>
                <td>{formatDate(String(start?.createdAt))}</td>
                <td></td>
                <td></td>
                <td>{start ? start.checkTime : "Sin información"}</td>
                <td>{end ? end.checkTime : "Sin información"}</td>
                <td></td>
                <td>{isNaN(diferenceInMinutes) ? 0:diferenceInMinutes}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default RowUnschedule;
