import * as yup from 'yup';

export const generalAttendanceReportSchema = yup.object().shape({
  start: yup
    .date()
    .required('La fecha de inicio es requerida')
    .typeError('La fecha de inicio debe ser una fecha válida')
    .max(yup.ref('end'), 'La fecha de inicio no puede ser mayor que la fecha de fin'),
  end: yup
    .date()
    .required('La fecha de fin es requerida')
    .typeError('La fecha de fin debe ser una fecha válida')
    .min(yup.ref('start'), 'La fecha de fin no puede ser menor que la fecha de inicio')
    .test(
      'max-range',
      'El rango entre las fechas no puede ser mayor a 16 días',
      function (endDate) {
        const { start } = this.parent;
        if (start && endDate) {
          const differenceInDays = (new Date(endDate) - new Date(start)) / (1000 * 60 * 60 * 24);
          return differenceInDays < 16;
        }
        return true;
      }
    ),
});