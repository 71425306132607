import { IGeneralAttendanceReport } from "../models/GeneralAttendanceReport";
import httpClient from "./httpClient";

const prefix = "/assistance-check";

export async function registerAssistance(id, data) {
  return (await httpClient.post(`${prefix}/register-assistance/${id}`, data))
    .data;
}

export async function findAssistanceByDate(date) {
  return (await httpClient.get(`${prefix}/date/${date}`)).data;
}

export async function findAssistanceByBranch(branchId) {
  return (await httpClient.get(`${prefix}/branch/${branchId}`)).data;
}

export async function findAssistanceByPartnerAndDate(partnerId, date) {
  return (await httpClient.get(`${prefix}/partner/${partnerId}/date/${date}`))
    .data;
}

export async function findChecksByPartnerAndDate(partnerId, date) {
  return (
    await httpClient.get(`${prefix}/partner/${partnerId}/date-check/${date}`)
  ).data;
}

export async function updateScheduleStatus(id, scheduleId) {
  return (
    await httpClient.patch(`${prefix}/status/${id}/schedule/${scheduleId}`)
  ).data;
}

export async function updateScheduleStatusOut(id, scheduleId) {
  return (
    await httpClient.patch(`${prefix}/statusOut/${id}/schedule/${scheduleId}`)
  ).data;
}

export async function updateAssistance(id, data) {
  return (await httpClient.patch(`${prefix}/${id}`, { ...data })).data;
}

export async function updateScheduleStatusName(id, scheduleId) {
  return (
    await httpClient.patch(`${prefix}/schedule/status/${id}/${scheduleId}`)
  ).data;
}

export async function updateScheduleCheckBranch(id, scheduleId) {
  return (
    await httpClient.patch(`${prefix}/branch/${id}/schedule/${scheduleId}`)
  ).data;
}

export async function findByExactDateAndBranch(exactDate, branchId) {
  return (
    await httpClient.get(`${prefix}/exact-date/${exactDate}/branch/${branchId}`)
  ).data;
}

export async function findDifferentBranch(query) {
  return (await httpClient.get(`${prefix}/different-branch${query}`)).data;
}

export async function findMissingChecks() {
  return (await httpClient.get(`${prefix}/missing-checks`)).data;
}

export async function reactivatePartner(partnerId) {
  return (await httpClient.patch(`${prefix}/reactivate-partner/${partnerId}`))
    .data;
}

export async function findAssistanceByUserAndDate(userId, date, time) {
  return (
    await httpClient.get(
      `${prefix}/find-assistance-by-user-date-and-time/${userId}/${date}/${time}`
    )
  ).data;
}

export async function getParnerKey(partnerId) {
  return (await httpClient.get(`${prefix}/key/partner/${partnerId}`)).data;
}

export async function authorizeAssistance(dataQr, buildId, userId) {
  return (
    await httpClient.post(`${prefix}/authorize-assistance`, {
      dataQr,
      buildId,
      userId,
    })
  ).data;
}

export async function findAllAssistance(query = "") {
  return (await httpClient.get(`${prefix}/all/find?${query}`)).data;
}

export async function fetchAssistenceByUser(partner: number, date: string) {
  return (
    await httpClient.get(
      `${prefix}/assitence-by-partner?partner=${partner}&date=${date}`
    )
  ).data;
}

export async function updateAssistanceCheck(id: number, info: {}) {
  return (await httpClient.put(`${prefix}/update-assistance-check/${id}`, info))
    .data;
}

export async function readIncidents(
  branch: number,
  date: string,
  type: string
) {
  return (
    await httpClient.get(
      `${prefix}/findRecordsWithoutScheduleAssigment?branch=${branch}&date=${date}&type=${type}`
    )
  ).data;
}

export async function updateIncident(reason: string, id: number) {
  return (await httpClient.patch(`${prefix}/update-incident/${id}`, { reason }))
    .data;
}

export async function saveSchedule(data) {
  return (await httpClient.post(`${prefix}/save-schedule`, data)).data;
}

export async function findeDuplicateDeviceCheck(branchId, query) {
  return (
    await httpClient.get(
      `${prefix}/partners-duplicate-deviceId/${branchId}?${query}`
    )
  ).data;
}

export async function findWihoutAssistance(date: string, branchId: number) {
  return (
    await httpClient.get(
      `${prefix}/find-without-assistance?date=${date}&branchId=${branchId}`
    )
  ).data;
}

export async function updateWithoutAssistance(id: number, data: {}) {
  return (
    await httpClient.patch(`${prefix}/update-without-assistance/${id}`, data)
  ).data;
}

export async function findOperationalIncidents(date: string) {
  return (
    await httpClient.get(`${prefix}/find-operational-incidents?date=${date}`)
  ).data;
}

export async function findUnscheduleChecks(branch: number, dateStart: string, dateEnd) {
  return (
    await httpClient.get(
      `${prefix}/find-unschedule-checks?branch=${branch}&dateEnd=${dateEnd}&dateStart=${dateStart}`
    )
  ).data;
}

export async function findAssistanceByPartnerId(data: IGeneralAttendanceReport) {
  return (
    await httpClient.get(`${prefix}/find-all-schedules?start=${data.start}&end=${data.end}&branch=${-1}&partner=${-1}`)  
  ).data;
}