import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useParams } from "react-router-dom";

const useGetId = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  let id;

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  return id;
};

export default useGetId;
