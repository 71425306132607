import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import App from "./../app.jsx";

import ExtraError from "./../pages/extra/extra-error.js";
import LoginV1 from "./../pages/user/login-v1.js";
import LoginV2 from "./../pages/user/login-v2.tsx";
import LoginV3 from "./../pages/user/login-v3.js";
import RegisterV3 from "./../pages/user/register-v3.js";
import HelperCSS from "./../pages/helper/helper-css.js";
import FormPreguntasInciales from "../pages/preguntas-iniciales/form.js";
import Confirmation from "../pages/preguntas-iniciales/confirmation.js";
import FormPostulante from "../pages/postulante/form.js";
import FormResult from "../pages/result/form.js";
import Solicitudes from "../pages/solicitudes/lista.js";
import TalentoHumano from "../pages/talento-humano/lista.js";
import Calendar from "../pages/calendar/calendar.js";
import ListUsers from "../pages/user/ListUsers.tsx";
import Dashboard from "../pages/Dashboard.tsx";
import FormElements from "../pages/form/form-elements.js";
import FormPlugins from "../pages/form/form-plugins.js";
import NewUser from "../pages/user/NewUser.tsx";
import EditUser from "../pages/user/EditUser.tsx";
import Historial from "../pages/postulante/Historial.tsx";
import Print from "../pages/postulante/Print.tsx";
// import Catalog from "../pages/catalog/catalog.js";
import ListCatalog from "../pages/catalog/ListCatalog.tsx";
import FetchCollaborator from "../pages/postulante/FetchCollaborator.tsx";
import Vacantes from "../pages/vacantes/list.tsx";
import ListColaboradores from "../pages/colaboradores/ListColaboradores.tsx";
import ListListaNegra from "../pages/lista-negra/ListListaNegra.tsx";
import ListSolicitudes from "../pages/solicitudes/ListSolicitudes.tsx";
import ProfilePartner from "../pages/profile-partner/ProfilePartner.tsx";
import Puestos from "../pages/puestos/Puestos.tsx";
import NewPosition from "../pages/puestos/NewPosition.tsx";
import EditPosition from "../pages/puestos/EditPosition.tsx";
import MyExpedient from "../pages/worker/MyExpedient.tsx";
import Tallas from "../pages/catalogo/Tallas.tsx";
import Catalogo from "../pages/catalogo/Catalogo.tsx";
import ImportPartners from "../pages/colaboradores/ImportPartners.tsx";
import ImportListaNegra from "../pages/lista-negra/ImportListaNegra.tsx";
import Reportes from "../pages/reports/ContractSignedReport.tsx";
import NewPlantilla from "../components/plantilla/NewPlantilla.tsx";
import Plantillas from "../pages/plantillas/Plantillas.tsx";
import Sucursales from "../pages/catalogo/Sucursales.tsx";
import SucursalesForm from "../components/catalogo/Sucursales/SucursalesForm.tsx";
import SucursalesHorio from "../components/catalogo/Sucursales/SucursalesHorio.tsx";
import ReporteSucursal from "../components/catalogo/Sucursales/ReporteSucursal.tsx";
import RelojChecador from "../pages/reloj-checador/RelojChecador.tsx";
import ConfigPage from "../pages/config/ConfigPage.tsx";
import Assistances from "../components/catalogo/Sucursales/Assistances.tsx";
import ScheduleRealTimeComponent from "../components/catalogo/Sucursales/componentes/ScheduleRealTimeComponent.tsx";
import BirthDatesReport from "../components/catalogo/Sucursales/BirthDatesReport.tsx";
import BirthDatesReportGeneral from "../components/catalogo/Sucursales/BirthDatesReportGeneral.tsx";
import Traspasos from "../pages/traspasos/Traspasos.tsx";
import PartnersByBranch from "../components/catalogo/Sucursales/PartnersByBranch.tsx";
import ReportePlantilla from "../components/catalogo/Sucursales/ReportePlantilla.tsx";
import ResumenHorarios from "../components/catalogo/Sucursales/ResumenHorarios.tsx";
import ResumenHorariosAsistencia from "../components/catalogo/Sucursales/ResumenHorariosAsistencia.tsx";
import Entradas from "../pages/entradas/Entradas.tsx";
import CoberturaPuestos from "../components/catalogo/Sucursales/CoberturaPuestos.tsx";
import NewContract from "../pages/puestos/NewContract.tsx";
import Chequeo from "../components/catalogo/Sucursales/componentes/Chequeo.tsx";
import Inasistencias from "../components/catalogo/Sucursales/inaisistencias/Inasistencias.tsx";
import Incidencias from "../components/catalogo/Sucursales/inciencias/Incidencias.tsx";
import NotificationsUsers from "../pages/notification-user/NotificationsUsers.tsx";
import NewNotification from "../pages/notification-user/components/NewNotification.tsx";
import EditNotification from "../pages/notification-user/components/EditNotification.tsx";
import EditOrderPosition from "../components/config/EditOrderPosition.tsx";
import ReporteSolicitudes from "../pages/solicitudes/ReporteSolicitudes.tsx";
import Reports from "../pages/Reports.tsx";
import TemplateLeaders from "../pages/reports/TemplateLeaders.tsx";
import Badges from "../pages/badges/Badges.tsx";
import ApprovedIncapacity from "../pages/incapacities/ApprovedIncapacity.tsx";
import EditIncapacityPartner from "../pages/incapacities/EditIncapacityPartner.tsx";
import NewIncapacity from "../pages/incapacities/NewIncapacity.tsx";
import ChilitosStats from "../pages/reports/ChilitosStats.tsx";
import DocumentsBranch from "../pages/reports/DocumentsBranch.tsx";
import Privacy from "../pages/Privacy.tsx";
import DobleFunctionReport from "../components/catalogo/Sucursales/DobleFunctionReport.tsx";
import DobleShiftReport from "../components/catalogo/Sucursales/DobleShiftReport.tsx";
import AllWeekReport from "../components/catalogo/Sucursales/AllWeekReport.tsx";
import SingleWorkDayReport from "../components/catalogo/Sucursales/SingleWorkDayReport.tsx";
import ReportDuplicateDevices from "../components/catalogo/Sucursales/componentes/ReportDuplicateDevices.tsx";
import Inassistence from "../components/catalogo/Sucursales/cubrirPuestos/Inassistence.tsx";



const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [
      {
        path: "",
        element: <Dashboard />,
        roles: [
          "Administrador",
          "Lider de sucursal",
          "Reclutamiento", // Upload files
          "Trabajador",
          "Reclutador/Archivos",
          "Contador",
          "Reclutador",
          "Talento humano",
          "Reloj checador",
          "Capacitación y Cultura",
        ],
      },
      {
        path: "postulante",
        element: <Outlet />,
        children: [
          {
            path: "form",
            element: <FormPostulante />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Reclutamiento",
              "Reclutador",
            ],
          },
          {
            path: "historial/:curp",
            element: <Historial />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Reclutamiento",
              "Reclutador",
            ],
          },
        ],
      },
      {
        path: "birthdates",
        element: <BirthDatesReportGeneral />,
        roles: ["Administrador", "Reclutamiento", "Capacitación y Cultura"],
      },
      {
        path: "reportes",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Reports />,
            roles: ["Administrador"],
          },
          {
            path: "contratos-firmados",
            element: <Reportes />,
            roles: ["Administrador"],
          },
          {
            path: "sucursales",
            element: <ReporteSolicitudes />,
            roles: ["Administrador"],
          },
          {
            path: "plantilla-lideres",
            element: <TemplateLeaders />,
            roles: ["Administrador"],
          },
          {
            path: "estadisticas-chilito",
            element: <ChilitosStats />,
            roles: ["Administrador"],
          },
          {
            path: "documentos/:id",
            element: <DocumentsBranch />,
            roles: ["Administrador"],
          },
          // { path: "/cumpleaños", element: <Reportes />, roles: ["Administrador"] },
        ],
      },
      {
        path: "result",
        element: <Outlet />,
        children: [
          {
            path: "form",
            element: <FormResult />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Reclutador/Archivos",
              "Reclutador",
            ],
          },
        ],
      },
      {
        path: "solicitudes/",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ListSolicitudes />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Reclutador/Archivos",
              "Reclutador",
            ],
          },
          {
            path: "aprobar/:id",
            element: <FetchCollaborator />,
            roles: ["Administrador", "Lider de sucursal", "Reclutador"],
          },
        ],
      },
      {
        path: "colaboradores/",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ListColaboradores />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Contador",
              "Reclutador",
              "Talento humano",
            ],
          },
          {
            path: "detalles/:id",
            element: <ProfilePartner />,
            roles: [
              "Administrador",
              "Lider de sucursal",
              "Contador",
              "Reclutador",
              "Talento humano",
            ],
          },
          {
            path: "aprobar-incapacidad/:id",
            element: <ApprovedIncapacity />,
            roles: ["Administrador", "Lider de sucursal"],
          },
          {
            path: "nueva-incapacidad/:id",
            element: <NewIncapacity />,
            roles: ["Administrador", "Lider de sucursal"],
          },
          {
            path: "importar",
            element: <ImportPartners />,
            roles: ["Administrador", "Lider de sucursal"],
          },
        ],
      },
      {
        path: "calendar",
        element: <Calendar />,
        roles: ["Administrador", "Reclutador"],
      },
      {
        path: "catalog",
        element: <ListCatalog />,
        roles: ["Administrador"],
      },
      {
        path: "usuarios/",
        element: <Outlet />,
        children: [
          // Si no agregamos roles es poque solo es para admin
          {
            path: "",
            element: <ListUsers />,
            roles: ["Administrador"],
          },
          { path: "nuevo", element: <NewUser />, roles: ["Administrador"] },
          {
            path: "editar/:id",
            element: <EditUser />,
            roles: ["Administrador"],
          },
        ],
      },
      {
        path: "login",
        element: <Navigate to="/" />,
      },
      {
        path: "form",
        element: <Outlet />,
        children: [
          {
            path: "elements",
            element: <FormElements />,
            roles: ["Administrador", "Lider de sucursal"],
          },
          {
            path: "plugins",
            element: <FormPlugins />,
            roles: ["Administrador", "Lider de sucursal"],
          },
        ],
      },
      {
        path: "user",
        element: <Outlet />,
        children: [
          { path: "login-v1", element: <LoginV1 /> },
          { path: "login-v2", element: <LoginV2 /> },
          { path: "login-v3", element: <LoginV3 /> },
          { path: "register-v3", element: <RegisterV3 /> },
        ],
      },
      {
        path: "helper/css",
        element: <HelperCSS />,
      },
      {
        path: "vacantes",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Vacantes />,
            roles: ["Administrador", "Reclutador"],
          },
        ],
      },
      {
        path: "lista-negra",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ListListaNegra />,
            roles: ["Administrador", "Reclutador", "Talento humano"],
          },
          {
            path: "importar",
            element: <ImportListaNegra />,
            roles: ["Administrador"],
          },
        ],
      }, 
      {
        path: "mi-expediente",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <MyExpedient />,
            roles: ["Trabajador"],
          },
          {
            path: "incapacidad/:id",
            element: <EditIncapacityPartner />,
            roles: ["Trabajador"],
          },
          {
            path: "nuevo/:id",
            element: <NewIncapacity />,
            roles: ["Trabajador", "Administrador"],
          },
        ],
      },
      {
        path: "insignias",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Badges />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "puestos",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Puestos />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "nuevo",
            element: <NewPosition />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "nuevo-contrato",
            element: <NewContract />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "editar/:id",
            element: <EditPosition />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "plantillas",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Plantillas />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "nuevo",
            element: <NewPlantilla />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "editar/:id",
            element: <NewPlantilla />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "notificaciones",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <NotificationsUsers />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "nueva-notificacion",
            element: <NewNotification />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "editar/:id",
            element: <EditNotification />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "sucursales",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Sucursales />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "incidencias/:id",
            element: <Incidencias />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "chequeo",
            element: <Chequeo />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "chequeo/:id",
            element: <Chequeo />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "inasistencias",
            element: <Inasistencias />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "inasistencias/:id",
            element: <Inasistencias />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "nuevo",
            element: <SucursalesForm />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "editar/:id",
            element: <SucursalesForm />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "birthdates",
            element: <BirthDatesReportGeneral />,
            roles: ["Administrador", "Reclutamiento", "Capacitación y Cultura"],
          },
          {
            path: "birthdates/:id",
            element: <BirthDatesReport />,
            roles: [
              "Administrador",
              "Reclutamiento",
              "Lider de sucursal",
              "Capacitación y Cultura",
            ],
          },
          {
            path: "colaboradores/:id",
            element: <PartnersByBranch />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "horario/:id",
            element: <SucursalesHorio />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "reporte/:id",
            element: <ReporteSucursal />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "reporte-plantilla/:id",
            element: <ReportePlantilla />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "cobertura-puestos/:id",
            element: <CoberturaPuestos />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-horario/:id",
            element: <ResumenHorarios />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-horario-asistencia/:id",
            element: <ResumenHorariosAsistencia />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-all-week/:id",
            element: <AllWeekReport />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-doble-funcion/:id",
            element: <DobleFunctionReport />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-duplicate-device/:id",
            element: <ReportDuplicateDevices />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-single-work/:id",
            element: <SingleWorkDayReport />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-doble-turno/:id",
            element: <DobleShiftReport />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias",
            element: <Assistances />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias/colaborador/:id",
            element: <Assistances />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias/:id/horario",
            element: <ScheduleRealTimeComponent />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "traspasos/:id",
            element: <Traspasos />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "cubrir-puestos-urgente/:id",
            element: <Inassistence />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },

        ],
      },
      {
        path: "sucursal",
        element: <Outlet />,
        children: [
          {
            path: "colaboradores",
            element: <PartnersByBranch />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "birthdates",
            element: <BirthDatesReport />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "horario",
            element: <SucursalesHorio />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "reporte",
            element: <ReporteSucursal />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "reporte-plantilla",
            element: <ReportePlantilla />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-horario",
            element: <ResumenHorarios />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "resumen-horario-asistencia",
            element: <ResumenHorariosAsistencia />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias",
            element: <Assistances />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias/colaborador/:id",
            element: <Assistances />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "asistencias/:id/horario",
            element: <ScheduleRealTimeComponent />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "traspasos",
            element: <Traspasos />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "incidencias",
            element: <Incidencias />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
          {
            path: "reporte-reloj",
            element: <Chequeo />,
            roles: ["Administrador", "Reclutamiento", "Lider de sucursal"],
          },
        ],
      },
      {
        path: "reloj-checador",
        element: <RelojChecador />,
        roles: ["Administrador", "Lider de sucursal", "Reloj checador"],
      },
      {
        path: "configuracion",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ConfigPage />,
            roles: ["Administrador", "Reclutamiento"],
          },
          {
            path: "order-puestos",
            element: <EditOrderPosition />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "registro-de-entradas",
        element: <Entradas />,
        children: [
          {
            path: "",
            element: <ConfigPage />,
            roles: ["Administrador", "Reclutamiento"],
          },
        ],
      },
      {
        path: "*",
        element: <ExtraError />,
        roles: [
          "Administrador",
          "Lider de sucursal",
          "Reclutamiento",
          "Trabajador",
          "Reclutador/Archivos",
          "Contador",
          "Reclutador",
          "Talento humano",
          "Capacitación y Cultura",
        ],
      },
    ],
  },
];

export const PublicRoutes = [
  {
    path: "politica-de-privacidad",
    element: <Privacy />,
  },
  {
    path: "login",
    element: <LoginV2 />,
  },
  {
    path: "unete",
    element: <Outlet />,
    children: [{ path: "", element: <LoginV3 /> }],
  },
  {
    path: "preguntas-iniciales",
    element: <Outlet />,
    children: [
      { path: "form", element: <FormPreguntasInciales /> },
      { path: "confirmation", element: <Confirmation /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/unete" />,
  },
];

export default AppRoute;
