import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateNewCode } from "../../../../services/assistenceCode.ts";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";

const ModalCodeAssitence = ({
  partnerId,
  setShowModal,
}: {
  partnerId: number;
  setShowModal: (_: boolean) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [generatedCode, setGeneratedCode] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      expirationDate: new Date(),
      reason: "",
    },
    validationSchema: Yup.object({
      expirationDate: Yup.date().min(new Date(), "La fecha no puede ser anterior al día de hoy").required(
        "La fecha de expiración es obligatoria"
      ),

      reason: Yup.string().required("La descripción es obligatoria"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const data = {
          partnerId,
          expirationDate: values.expirationDate,
          reason: values.reason,
        };

        const response = await generateNewCode(data);
        setGeneratedCode(response.data.code);
      } catch (error) {
        console.error("Error al generar el código de asistencia:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClearForm = () => {
    formik.resetForm();
    setGeneratedCode("");
  };

  const isFormFilled = !!formik.values.expirationDate || !!formik.values.reason;

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="expirationDate" className="form-label">
            Fecha de expiración *
          </label>
          <input
            type="date"
            id="expirationDate"
            className="form-control"
            value={formik.values.expirationDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.expirationDate && formik.errors.expirationDate ? (
            <div className="text-danger">{formik.errors.expirationDate}</div>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="reason" className="form-label">
            Descripción *
          </label>
          <textarea
            id="reason"
            className="form-control"
            rows={4}
            value={formik.values.reason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.reason && formik.errors.reason ? (
            <div className="text-danger">{formik.errors.reason}</div>
          ) : null}
        </div>

        {generatedCode && (
          <div className="alert alert-success text-center ">
            <h1 style={{ fontWeight: "bold" }}>{generatedCode}</h1>
          </div>
        )}

        <div className="d-flex flex-row mt-4">
          <button
            type="button"
            className="btn btn-secondary me-2"
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary me-2"
            onClick={handleClearForm}
            disabled={!isFormFilled}
          >
            Limpiar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading || !formik.isValid || !formik.dirty}
          >
            {loading ? "Guardando..." : "Guardar"}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ModalCodeAssitence;
