import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Partner } from "../../models/Partner";
import { getCurrencyFormat } from "../../utils/index.ts";
import {
  setCurrentUser,
  setDataToRemove,
  setDefaultSort,
  setFetch,
  setMessageModal,
  setModalChildren,
  setModalCredentials,
  setModalDelete,
  setModalTitle,
  setPage,
  setShow,
  setSortBy,
} from "../../store/slices/colaboradoresSlice.ts";
import { setShowModal as setShowModalSlice } from "../../store/slices/colaboradoresSlice.ts";
import moment from "moment";
import "moment/locale/es";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/img/ic_content.svg";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import ChangePositionModal from "../../components/colaboradores/ChangePositionModal.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { deletePartner } from "../../services/partner.service.ts";
import { Tooltip } from '@mui/material';
import { fixedHeadStyle, fixedStyle } from "../../utils/constanst.js";
moment.locale("es");

const TableColaboradores = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colaboradores, sortBy, defaultSort } = useSelector(
    (state: RootState) => state.colaboradores
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const formatDate = (date: string) => {
    const fechaFormateada = moment(date).format("dddd, D [de] MMMM [de] YYYY");

    return fechaFormateada;
  };

  const formatDateWithoutTime = (date: string) => {
    const fechaFormateada = moment(date).format("dddd, D [de] MMMM [de] YYYY");

    return fechaFormateada;
  };


  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";

    dispatch(setSortBy(sort));
    dispatch(setPage(0));

    dispatch(setDefaultSort(sortOrder));
  };

  const isTodayBirthday = (date: string) => {
    const birthday = moment(date).format("MM-DD");
    const currentYearBirthday = moment(birthday).year(moment().year());

    return currentYearBirthday.isSame(moment(), "day");
  }

  const handleDeletePartner = async (id: number) => {
    try {
      await deletePartner(id);
      dispatch(setFetch(!fetch));
      notification("Eliminado", "Colaborador eliminado con éxito", "success");
    }
    catch (error) {
      notification("Error", "Error al eliminar el colaborador", "danger");
    }
  }

  return (
    <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
            <th style={{ ...fixedStyle}as React.CSSProperties} onClick={() => setSort("id")}>
              Id {sortBy === "id" && getIconSort()}
            </th>
            <th onClick={() => setSort("name")}>
              Nombre completo {sortBy === "name" && getIconSort()}
            </th>
            <th onClick={() => setSort("phone")}>
              Celular {sortBy === "phone" && getIconSort()}
            </th>
            <th onClick={() => setSort("position")}>
              Puesto {sortBy === "position" && getIconSort()}
            </th>
            <th onClick={() => setSort("shift")}>
              Turno {sortBy === "shift" && getIconSort()}
            </th>
            <th onClick={() => setSort("branch")}>
              Sucursal {sortBy === "branch" && getIconSort()}
            </th>
            <th onClick={() => setSort("status")}>
              Status {sortBy === "status" && getIconSort()}
            </th>
            {/* <th>
              Baja temporal 
            </th> */}
            <th onClick={() => setSort("bandate")}>
              Fecha de baja {sortBy === "bandate" && getIconSort()}
            </th>
            <th style={{cursor: "default"}}>
            Fecha de entrada
            </th>
            {!user.branch && (
              <th onClick={() => setSort("salary")}>
                Salario {sortBy === "salary" && getIconSort()}
              </th>
            )}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {colaboradores?.length > 0 ? (
            colaboradores.map((colaborador: Partner, index) => (
              <tr key={index}>
                <td style={{ ...fixedStyle} as React.CSSProperties} >{colaborador.id}</td>
                <td>
                  {isTodayBirthday(colaborador.person.birthdate) ? (
                    <Tooltip title={`Cumpleañero`} arrow>
                      <div>
                        <i
                          className="fa-solid fa-cake me-2"
                          style={{ color: "#FFB22C" }}
                        ></i>
                        {toCamelCase(
                          `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`
                        )}
                        {colaborador.badges.length > 0 && (
                          <Tooltip
                            title={
                              <div>
                                {colaborador.badges.map((badge) => (
                                  <div key={badge.id}>
                                    <i
                                      className="fa-solid fa-medal me-2"
                                      style={{ color: "#FFD700" }}
                                    ></i>
                                    {badge.title}
                                  </div>
                                ))}
                              </div>
                            }
                            arrow
                          >
                            <i
                              className="fa-solid fa-medal ms-2"
                              style={{ color: "#FFD700" }}
                            ></i>
                          </Tooltip>
                        )}
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      {toCamelCase(
                        `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`
                      )}
                      {colaborador.badges.length > 0 && (
                        <Tooltip
                          title={
                            <div>
                              {colaborador.badges.map((badge) => (
                                <div key={badge.id}>
                                  <i
                                    className="fa-solid fa-medal me-2"
                                    style={{ color: "#FFD700" }}
                                  ></i>
                                  {badge.title}
                                </div>
                              ))}
                            </div>
                          }
                          arrow
                        >
                          <i
                            className="fa-solid fa-medal ms-2"
                            style={{ color: "#FFD700" }}
                          ></i>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </td>
                <td>{colaborador.person.phone}</td>
                <td>{colaborador.candidate?.vacancy?.position.name}</td>
                <td>{colaborador.candidate?.shift?.name}</td>
                <td>{colaborador.candidate?.branch?.name}</td>
                {/* <td>{item.contacto}</td> ???? */}
                {/* <td>{colaborador.partnerStatus.name}</td> */}
                <td>
                  {
                    colaborador.programedUnsubscribed ? (
                      <Tooltip title={`Baja programada para el ${moment(colaborador.programedUnsubscribed.banDate).format('DD-MM-YYYY')}`} arrow>
                        <div
                          style={{
                            backgroundColor:
                              colaborador.partnerStatus.name !== "Activo"
                                ? "#c74c4a"
                                : "#4ac792",
                            color: "white",
                            padding: "3px 7px",
                            borderRadius: "10px",
                            display: "inline-block",
                          }}
                        >
                          {colaborador.programedUnsubscribed && <i className="fa-solid fa-clock me-2"></i>}
                          {colaborador.partnerStatus.name}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        style={{
                          backgroundColor:
                            colaborador.partnerStatus.name !== "Activo"
                              ? "#c74c4a"
                              : "#4ac792",
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: "10px",
                          display: "inline-block",
                        }}
                      >
                        {colaborador.partnerStatus.name}
                      </div>
                    )
                  }

                </td>
                {/* <td>
                  <div
                    style={{
                      backgroundColor:
                        colaborador.temporarilySuspend
                          ? "#FFB22C"
                          : "#4ac792",
                      color: "white",
                      padding: "3px 7px",
                      borderRadius: "10px",
                      display: "inline-block",
                      marginInline: "auto",
                    }}
                  >
                    {colaborador.temporarilySuspend ? "Baja temporal" : "Activo"}
                  </div>
                </td> */}
                <td>
                  {colaborador.partnerStatus.name !== "Activo"
                    ? formatDate(colaborador.banDate)
                    : "No aplica"}
                </td>
                <td>
                  {formatDateWithoutTime(
                    colaborador.candidate?.recruitmentDate
                  )}
                </td>
                {!user.branch && (
                  <td>
                    {getCurrencyFormat(colaborador?.candidate?.monthlySalary)}
                  </td>
                )}
                <td>
                  {colaborador.partnerStatus.name === "Baja" && (
                    <Tooltip title={`Reactivar`} arrow>
                      <a
                        className="btn btn-azul m-2"
                        onClick={() => {
                          dispatch(setShow(true));
                          dispatch(
                            setCurrentUser({
                              id: colaborador.id,
                              name: `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`,
                              curp: colaborador.person.curp,
                            })
                          );
                        }}
                      >
                        <i className="fa-solid fa-check"></i>
                      </a>
                    </Tooltip>
                  )}
                  {colaborador.partnerStatus.name !== "Baja" && (
                    <Tooltip title={`Dar de baja`} arrow>
                      <a
                        className="btn btn-rojo m-2"
                        onClick={() => {
                          dispatch(setModalDelete(true));
                          dispatch(
                            setDataToRemove({
                              id: colaborador.id,
                              name: `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`,
                              programed: colaborador.programedUnsubscribed,
                            })
                          );
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa fa-ban"></i>
                      </a>
                    </Tooltip>
                  )}
                  <Tooltip title={`Credenciales`} arrow>
                    <a
                      className="btn btn-amarillo m-2"
                      onClick={() => {
                        dispatch(setModalCredentials(true));
                        dispatch(
                          setCurrentUser({
                            id: colaborador.id,
                            name: `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`,
                            curp: colaborador.person.curp,
                          })
                        );
                      }}
                    >
                      <i className="fa-solid fa-key"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={`Editar mensaje`} arrow>
                    <a
                      className="btn btn-verde m-2"
                      onClick={() => {
                        dispatch(setMessageModal(true));
                        dispatch(
                          setCurrentUser({
                            id: colaborador.id,
                            name: `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`,
                            curp: colaborador.person.curp,
                          })
                        );
                      }}
                    >
                      <i className="fa-solid fa-message"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={`Detalles`} arrow>
                    <a
                      className="btn btn-azul m-2"
                      onClick={() =>
                        navigate(`/colaboradores/detalles/${colaborador.id}`)
                      }
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={`Asistencias`} arrow>
                    <a
                      className="btn btn-amarillo m-2"
                      onClick={() => navigate(`/sucursales/asistencias/colaborador/${colaborador.id}`)}
                    >
                      <i className="fa-solid fa-list-check"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={`Cambiar de puesto`} arrow>
                    <a
                      href="#/"
                      className="btn btn-secondary m-2"
                      onClick={() => {
                        dispatch(setShowModalSlice(true));
                        dispatch(
                          setModalChildren(
                            <ChangePositionModal
                              closeFn={() => dispatch(setShowModalSlice(false))}
                              partner={colaborador}
                            />
                          )
                        );
                        dispatch(
                          setModalTitle(
                            `Cambiar de puesto a ${toCamelCase(
                              `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`
                            )}`
                          )
                        );
                      }}
                    >
                      <i className="fa-solid fa-signs-post"></i>
                    </a>
                  </Tooltip>
                  {/* {<a
                    className="btn btn-danger m-2"
                    onClick={() => handleDeletePartner(colaborador.id)}
                  >
                    borrar
                  </a> } */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={11}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no hay colaboradores
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableColaboradores;
