import React from "react";
import * as moment from "moment-timezone";
import { OperationalUrgency } from "../../../../models/operationalUrgency";

const OperationalUrgencyRow = ({
  item,
  no,
}: {
  item: OperationalUrgency;
  no: number;
}) => {
  const [minutesWithoutReplacement, setMinutesWithoutReplacement] =
    React.useState<number>(0);
  const [minutesWithReplacement, setMinutesWithReplacement] =
    React.useState<number>(0);
  const [percentageCovered, setPercentageCovered] = React.useState<number>(0);

  const collapseId = `collapse${no}`;
  const headingId = `heading${no}`;

  const total = item.lateArrivals.length;
  const recordsWithoutReplacement = item.lateArrivals.filter(
    (record) => !record.replacement
  );
  const recordsWithReplacement = item.lateArrivals.filter(
    (record) => record.replacement
  );

  const currentTime = moment().tz("America/Mexico_City").format("HH:mm");

  // Minutos sin asignar
  React.useEffect(() => {
    const totalMinutesWithoutReplacement = item.lateArrivals.reduce(
      (acc, record) => {
        const startTime = moment(record.startTime.split(" - ").at(0), "HH:mm");

        if (record?.replacement?.createdAt) {
          const replacementCreate = moment(record.replacement.createdAt)
            .tz("America/Mexico_City")
            .format("HH:mm");

          const replacementCreateMoment = moment(replacementCreate, "HH:mm");

          const diff = replacementCreateMoment.diff(startTime, "minutes");

          return acc + diff;
        }

        const diff = moment(currentTime, "HH:mm").diff(startTime, "minutes");

        return acc + diff;
      },
      0
    );
    setMinutesWithoutReplacement(totalMinutesWithoutReplacement);
  }, [item]);

  // Tiempo sin que llegue el reemplazo
  React.useEffect(() => {
    const totalMinutesWithReplacement = recordsWithReplacement.reduce(
      (acc, record) => {
        // Verificar si ya checo y comparalo con ese tiempo
        const replacementCreate = moment(record.replacement.createdAt)
          .tz("America/Mexico_City")
          .format("HH:mm");

        const replacementCreateMoment = moment(replacementCreate, "HH:mm");
        const currentTimeMoment = moment(currentTime, "HH:mm");

        const diff = currentTimeMoment.diff(replacementCreateMoment, "minutes");

        return acc + diff;
      },
      0
    );
    setMinutesWithReplacement(totalMinutesWithReplacement);
  }, [item]);

  // Porcentaje de las faltas que ya estan cubiertas
  React.useEffect(() => {
    const totalWithReplacement = recordsWithReplacement.length;

    console.log({totalWithReplacement, total})

    const percentage = (totalWithReplacement / total) * 100;

    setPercentageCovered(percentage);
  }, [item]);

  return (
    <React.Fragment key={no}>
      <tr style={{ textAlign: "center" }}>
        <td
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target={`#${collapseId}`}
          aria-expanded="false"
          aria-controls={collapseId}
        >
          <h4>{item.branch.name}</h4>
        </td>
        <td style={{ border: "none" }}>
          <h5>{total}</h5>
        </td>
        <td style={{ border: "none" }}>
          <h5>{minutesWithoutReplacement}</h5>
        </td>
        <td style={{ border: "none" }}>
          <h5>{minutesWithReplacement}</h5>
        </td>
        <td style={{ border: "none" }}>
          <h5>{percentageCovered.toFixed(0)}%</h5>
        </td>
      </tr>

      <tr>
        <div
          id={collapseId}
          className="accordion-collapse collapse"
          aria-labelledby={headingId}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">xd</div>
        </div>
      </tr>
    </React.Fragment>
  );
};

export default OperationalUrgencyRow;
