import React from "react";
import { useFormik } from "formik";
import PartnersAutocomplete from "../../../formik/PartnersAutocomplete.tsx";
import SelectHour from "../../../formik/SelectHour.tsx";
import CustomTextArea from "../../../formik/CustomTextArea.tsx";
import { PartnerWithoutAssistance } from "../../../../models/PartnerWithoutAssistance.tsx";
import InassistanceSchema from "../../../../validation/schemas/InassistanceSchema.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import moment from "moment";
import { updateWithoutAssistance } from "../../../../services/assistanceCheck.service.ts";

const ModalInassistance = ({
  close,
  record,
  refetch
}: {
  close: () => void;
  record: PartnerWithoutAssistance;
  refetch: () => Promise<void>;
}) => {
  const formik = useFormik({
    initialValues: {
      partnerReplacement: -99,
      entryTime: new Date(),
      observations: "",
    },
    validationSchema: InassistanceSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          ...values,
          entryTime: moment(values.entryTime).format("HH:mm"),
        };

        const response = await updateWithoutAssistance(record.id, data);

        console.log(response);
      } catch (error) {
        notification("error", error.message, "danger");
      }finally{
        await refetch();
        close();

      }
    },
  });
  return (
    <React.Fragment>
      <div className="row">
        <div
        // className="col-7"
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   flexDirection: "column",
        //   justifyContent: "center",
        // }}
        >
          <PartnersAutocomplete field="partnerReplacement" formik={formik} />
          <SelectHour
            field="entryTime"
            formik={formik}
            label="Hora de entrada"
          />

          <div className="mt-2" />
          <CustomTextArea
            field="observations"
            formik={formik}
            label="Observaciones"
            placeholder="Escribe alguna observación"
            smLabel={4}
            sm={8}
          />
        </div>
        {/* <div className="col-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <DemoItem>
                <StaticTimePicker
                  orientation="landscape"
                  onAccept={(e) => {
                    formik.setFieldValue("fakeTimeEntry", e);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div> */}
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-secondary me-2" onClick={() => close()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalInassistance;
