import React from "react";
import { PartnerWithoutAssistance } from "../../../../models/PartnerWithoutAssistance";
import PartnerDetailIncident from "../inciencias/PartnerDetailIncident.tsx";
import { Tooltip } from "@mui/material";

const TableInassistence = ({
  data,
  refetch,
  openModal,
}: {
  data: PartnerWithoutAssistance[];
  refetch: () => Promise<void>;
  openModal: (record: PartnerWithoutAssistance) => void;
}) => {
  return (
    <div className="table-sticky mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead className="sticky-thead table-light text-center">
          <tr>
            <th rowSpan={1} className="align-middle">
              Chilito con falta
            </th>
            <th rowSpan={1} className="align-middle">
              Hora de entrada
            </th>

            <th colSpan={1} className="align-middle">
              Chilito que cubre
            </th>
            <th colSpan={1} className="align-middle">
              Hora de llegada
            </th>
            <th rowSpan={1} className="align-middle">
              Informacion de la cobertura
            </th>
            <th rowSpan={1} className="align-middle">
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td className="text-center">
                <PartnerDetailIncident
                  record={item as any}
                  original
                  text={false}
                />
              </td>
              <td className="text-center">{item.startTime.split(" - ")[0]}</td>

              <td className="text-center">
                {item?.replacement ? (
                  <PartnerDetailIncident
                    record={item?.replacement as any}
                    replacement
                    text={false}
                  />
                ) : (
                  "Sin información"
                )}
              </td>

              <td className="text-center">
                {item?.replacement
                  ? item?.replacement?.entryTime
                  : "Sin información"}
              </td>
              <td className="text-center">
              {item?.replacement
                  ? item?.replacement?.observations
                  : "Sin información"}
              </td>
              <td className="text-center">
                <Tooltip title="Buscar chilito que lo cubra" arrow>
                  <button
                    type="button"
                    className="btn p-0"
                    onClick={() => openModal(item)}
                  >
                    <i className="fa-solid fa-magnifying-glass fs-1 mx-2 text-success" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableInassistence;
