import httpClient, { httpFormDataClient } from "./httpClient";

const prefix = "/attendance-wildcards";

export async function fetchWildcardHistory(partnerId) {
  return (await httpClient.get(`${prefix}/history/${partnerId}`)).data;
}

export async function fetchWildcardCodes(partnerId) {
  return (await httpClient.get(`${prefix}/generated/${partnerId}`)).data;
}

export async function generateNewCode(data) {
  return (await httpClient.post(`${prefix}`, data)).data;
}

export async function updateRemainingWildcards(partnerId, newCount) {
  return (
    await httpClient.patch(
      `${prefix}/update-remaining-wildcards/${partnerId}`,
      {
        newCount,
      }
    )
  ).data;
}

export async function fetchAssistanceByCode(code) {
  return (await httpClient.get(`${prefix}/assistance/${code}`)).data;
}

//AQUI LA FUNCION DEL PATHCH PARA SUBIR LAS ASSISTENCE
export async function registerAssistanceCheckByCode(formData) {
  try {
    const response = await httpFormDataClient.post(
      `/assistance-check/register-assistance-code`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error registrando asistencia", error);
    throw error;
  }
}

export async function fetchRemainingWildcardsPartner(partnerId) {
  return (await httpClient.get(`${prefix}/remainings/${partnerId}`)).data;
}