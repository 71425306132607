import React, { useState } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const ModalHistorySchedule = ({
  historyData,
  onView,
  onClose,
}: {
  historyData: any;
  onView: (records: any[]) => void;
  onClose: () => void;
}) => {
  const [showScrollIcon, setShowScrollIcon] = useState(true);

  const handleScrollDown = () => {
    const container = document.getElementById("history-container");
    if (container) {
      container.scrollBy({ top: 500, behavior: "smooth" });
    }
    setShowScrollIcon(false);
  };

  const handleView = (records: any[]) => {
    onView(records);
    onClose();
  };

  return (
    <div className="container-fluid">
      <div
        id="history-container"
        style={{ maxHeight: "480px", overflowY: "auto", position: "relative" }}
      >
        {historyData.map((historyGroup: any) => (
          <div key={historyGroup.id} className="mb-3">
            <div className="card">
              <div className="card-header">
                <p>
                  <span className="fw-bold">Creado por:</span>{" "}
                  {historyGroup?.createdBy?.name}{" "}
                  {historyGroup?.createdBy?.lastName}
                </p>
                <p>
                  <span className="fw-bold mb-0"> Guardado el: </span>
                  {format(
                    new Date(historyGroup.createdAt),
                    "dd MMMM yyyy HH:ss",
                    { locale: es }
                  )}
                </p>
                <div className="d-flex mt-0 align-items-center justify-content-between">
                  <p className="mb-0 fw-bold">
                    Numero de registros: {historyGroup.records.length}
                  </p>
                  <div className="d-flex">
                    <button
                      className="btn btn-success"
                      onClick={() => handleView(historyGroup.records)}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                    {/* <button
      className="ms-2 btn btn-secondary"
      onClick={() => handleView(historyGroup.records)}
    >
      <i className="fas fa-undo"></i>
    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {historyData.length === 0 && (
          <div className="d-flex justify-content-center">
            <p>No hay historial de cambios</p>
          </div>
        )}

        {showScrollIcon && historyData.length > 3 && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              cursor: "pointer",
            }}
            onClick={handleScrollDown}
          >
            <i className="fa-solid fa-arrow-down fa-2x"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalHistorySchedule;
